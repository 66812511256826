window.Vue = require("vue");

import {
  SkeletonPlugin,
  ModalPlugin,
  PaginationPlugin,
  FormDatepickerPlugin,
  FormSpinbuttonPlugin,
  TooltipPlugin,
  SpinnerPlugin,
  TablePlugin,
  CollapsePlugin,
  FormCheckboxPlugin,
  FormTimepickerPlugin
} from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.min.css";

const plugins = [
  PaginationPlugin,
  ModalPlugin,
  SkeletonPlugin,
  FormDatepickerPlugin,
  FormSpinbuttonPlugin,
  TooltipPlugin,
  SpinnerPlugin,
  TablePlugin,
  CollapsePlugin,
  FormCheckboxPlugin,
  FormTimepickerPlugin
];

plugins.forEach((plugin) => Vue.use(plugin));
