import $axios from "../../api";

const state = () => ({
    isLoading: false,
    isSuccess: false,
    isErrors: "",
    page: 1,
    listUserPriceGroup: [],
    userPriceGroupDetail: "",
});

const mutations = {
    SET_LOADING(state, payload) {
        state.isLoading = payload;
    },
    ASSIGN_ERRORS(state, payload) {
        state.isErrors = payload;
    },
    ASSIGN_LIST(state, payload) {
        state.listUserPriceGroup = payload;
    },
    ASSIGN_USER_PRICE_GROUP_DETAIL(state, payload) {
        state.userPriceGroupDetail = payload;
    },
    SET_IS_SUCCESS(state, payload) {
        state.isSuccess = payload;
    },
    SET_PAGE(state, payload) {
        state.page = payload;
    },
};

const actions = {
    getList({ commit, state, rootState, dispatch }, payload) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            $axios
                .get(
                    `priceuser/list?page=${state.page}&view=${
                        rootState.Main.view
                    }${payload ? payload : ""}`
                )
                .then((res) => {
                    commit("ASSIGN_LIST", res.data.data);
                    commit("SET_LOADING", false);
                })
                .catch((error) => {
                    commit("SET_LOADING", false);
                    dispatch("Main/globalSweetAlert", error.response, {
                        root: true,
                    });
                });
        });
    },
    view({ commit, state, rootState, dispatch }, payload) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            $axios
                .get(`priceuser/view?id=${payload}`)
                .then((res) => {
                    commit("ASSIGN_USER_PRICE_GROUP_DETAIL", res.data.data);
                    commit("SET_LOADING", false);
                })
                .catch((error) => {
                    commit("SET_LOADING", false);
                    dispatch("Main/globalSweetAlert", error.response, {
                        root: true,
                    });
                });
        });
    },
    create({ commit, state, rootState, dispatch }, payload) {
        commit("SET_LOADING", true);
        commit("SET_IS_SUCCESS", false);
        return new Promise((resolve, reject) => {
            $axios
                .post(`priceuser/create`, payload)
                .then((res) => {
                    commit("SET_IS_SUCCESS", true);
                    commit("SET_LOADING", false);
                })
                .catch((error) => {
                    commit("ASSIGN_ERRORS", error.response.data.errors);
                    commit("SET_LOADING", false);
                    dispatch("Main/globalSweetAlert", error.response, {
                        root: true,
                    });
                });
        });
    },
    update({ commit, state, rootState, dispatch }, payload) {
        commit("SET_LOADING", true);
        commit("SET_IS_SUCCESS", false);
        return new Promise((resolve, reject) => {
            $axios
                .post(`priceuser/update`, payload)
                .then((res) => {
                    commit("SET_IS_SUCCESS", true);
                    commit("SET_LOADING", false);
                })
                .catch((error) => {
                    commit("ASSIGN_ERRORS", error.response.data.errors);
                    commit("SET_LOADING", false);
                    dispatch("Main/globalSweetAlert", error.response, {
                        root: true,
                    });
                });
        });
    },
    showhide({ commit, state, rootState, dispatch }, payload) {
        commit("SET_LOADING", true);
        commit("SET_IS_SUCCESS", false);
        return new Promise((resolve, reject) => {
            $axios
                .post(`priceuser/showhide`, payload)
                .then((res) => {
                    commit("SET_IS_SUCCESS", true);
                    commit("SET_LOADING", false);
                })
                .catch((error) => {
                    commit("SET_LOADING", false);
                    dispatch("Main/globalSweetAlert", error.response, {
                        root: true,
                    });
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
