import $axios from "../../api";

const state = () => ({
  isLoading: false,
  isErrors: "",
  listSync: [],
  is_resync: false,
  page: 1,
});

const mutations = {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  ASSIGN_ERRORS(state, payload) {
    state.isErrors = payload;
  },
  ASSIGN_LISTSYNC(state, payload) {
    state.listSync = payload;
  },
  ASSIGN_ISRESYNC(state, payload) {
    state.is_resync = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
};

const actions = {
  req_GetListUnion({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    payload = "?page=" + state.page + payload;
    return new Promise((resolve, reject) => {
      $axios
        .get("/sync-sales" + payload)
        .then((res) => {
          commit("ASSIGN_LISTSYNC", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  req_Resync({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    payload = {
      docentry: payload,
      resync: "transaction",
    };
    return new Promise((resolve, reject) => {
      $axios
        .post("/sync-sales/resync", payload)
        .then((res) => {
          commit("ASSIGN_ISRESYNC", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
