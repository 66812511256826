import $axios from "../../api";

const state = () => ({
    isLoading: false,
    isErrors: "",
    isSuccess: false,
    page: 1,
    view: 20,
    galleryList: [],
    unsplashList: [],
    galleryDetail: "",
    imageUrl: ""
});

const mutations = {
    SET_LOADING(state, payload) {
        state.isLoading = payload;
    },
    ASSIGN_ERRORS(state, payload) {
        state.isErrors = payload;
    },
    SET_PAGE(state, payload) {
        state.page = payload;
    },
    ASSIGN_GALLERY_LIST(state, payload) {
        state.galleryList = payload;
    },
    SET_SUCCESS(state, payload) {
        state.isSuccess = payload;
    },
    ASSIGN_GALLERY_DETAIL(state, payload) {
        state.galleryDetail = payload;
    },
    ASSIGN_UNSPLASH_LIST(state, payload) {
        state.unsplashList = payload;
    },
    ASSIGN_IMAGE_URL(state, payload) {
        state.imageUrl = payload;
    }
};

const actions = {
    getGalleryList({ commit, state, rootState, dispatch }, payload) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            $axios
                .get(
                    `gallery/list?page=${state.page}&view=${state.view}${payload ? payload : ""
                    }`
                )
                .then((res) => {
                    commit("ASSIGN_GALLERY_LIST", res.data.data);
                    commit("SET_LOADING", false);
                })
                .catch((error) => {
                    commit("SET_LOADING", false);
                    dispatch("Main/globalSweetAlert", error.response, {
                        root: true,
                    });
                });
        });
    },
    getGalleryDetail({ commit, state, rootState, dispatch }, payload) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            $axios
                .get(`gallery/view?id=${payload ? payload : ""}`)
                .then((res) => {
                    commit("ASSIGN_GALLERY_DETAIL", res.data.data);
                    commit("SET_LOADING", false);
                })
                .catch((error) => {
                    commit("SET_LOADING", false);
                    dispatch("Main/globalSweetAlert", error.response, {
                        root: true,
                    });
                });
        });
    },
    deleteData({ commit, state, rootState, dispatch }, payload) {
        commit("SET_SUCCESS", false);
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            $axios
                .post(`gallery/delete`, payload)
                .then((res) => {
                    commit("SET_SUCCESS", true);
                    commit("SET_LOADING", false);
                })
                .catch((error) => {
                    commit("SET_LOADING", false);
                    dispatch("Main/globalSweetAlert", error.response, {
                        root: true,
                    });
                });
        });
    },
    createGallery({ commit, state, rootState, dispatch }, payload) {
        commit("SET_SUCCESS", false);
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            $axios
                .post(`gallery/create`, payload)
                .then((res) => {
                    commit("ASSIGN_IMAGE_URL", res.data.data)
                    commit("SET_SUCCESS", true);
                    commit("SET_LOADING", false);
                })
                .catch((error) => {
                    commit("ASSIGN_ERRORS", error.response.data.errors);
                    commit("SET_LOADING", false);
                    dispatch("Main/globalSweetAlert", error.response, {
                        root: true,
                    });
                });
        });
    },
    updateGallery({ commit, state, rootState, dispatch }, payload) {
        commit("SET_SUCCESS", false);
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            $axios
                .post(`gallery/update`, payload)
                .then((res) => {
                    commit("SET_SUCCESS", true);
                    commit("SET_LOADING", false);
                })
                .catch((error) => {
                    commit("ASSIGN_ERRORS", error.response.data.errors);
                    commit("SET_LOADING", false);
                    dispatch("Main/globalSweetAlert", error.response, {
                        root: true,
                    });
                });
        });
    },
    getImgFromUnsplash({ commit, state, rootState, dispatch }, payload) {
        commit("SET_LOADING", true);
        const key = 'jZstFIoH2LArO3y9qEfRpA8EIuCeVPL5iOhIN0347tg'
        return new Promise((resolve, reject) => {
            $axios
                .get(
                    `https://api.unsplash.com/search/photos?client_id=${key}&query=${payload ? payload : "healthy food"}&page=${state.page}&per_page=20`
                )
                .then((res) => {
                    commit("ASSIGN_UNSPLASH_LIST", res.data);
                    commit("SET_LOADING", false);
                })
                .catch((error) => {
                    commit("SET_LOADING", false);
                    dispatch("Main/globalSweetAlert", error.response, {
                        root: true,
                    });
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
