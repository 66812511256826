window.Vue = require("vue");

Vue.mixin({
  methods: {
    globalFormatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    globalFormatFloat(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    globalWeightConverter(data) {
      let val = parseInt(data);
      const sum = val / 1000;
      if (val > 0) {
        if (sum < 1) {
          return `${val} gr`;
        } else {
          return `${sum} kg`;
        }
      } else {
        return val;
      }
    },
    globalToast(icon, title) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        icon: icon || "success",
        title: title || "Process successfully",
      });
    },
  },
});
