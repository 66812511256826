export default [
  {
    name: "accbarang_index",
    path: "/barang",
    component: () => import("../pages/acc/barang/index"),
  },
  {
    name: "accbarang_create",
    path: "/barang/create",
    component: () => import("../pages/acc/barang/manage"),
  },
  {
    name: "accbarang_update",
    path: "/barang/update/:id",
    component: () => import("../pages/acc/barang/manage"),
  },

  {
    name: "acccustomer_index",
    path: "/customer",
    component: () => import("../pages/acc/customer/index"),
  },
  {
    name: "acccustomer_create",
    path: "/customer/create",
    component: () => import("../pages/acc/customer/manage"),
  },
  {
    name: "acccustomer_update",
    path: "/customer/update/:id",
    component: () => import("../pages/acc/customer/manage"),
  },
  
  {
    name: "accpabrik_index",
    path: "/pabrik",
    component: () => import("../pages/acc/pabrik/index"),
  },
  {
    name: "accpabrik_create",
    path: "/pabrik/create",
    component: () => import("../pages/acc/pabrik/manage"),
  },
  {
    name: "accpabrik_update",
    path: "/pabrik/update/:id",
    component: () => import("../pages/acc/pabrik/manage"),
  },

  {
    name: "accwarna_index",
    path: "/warna",
    component: () => import("../pages/acc/warna/index"),
  },
  {
    name: "accwarna_create",
    path: "/warna/create",
    component: () => import("../pages/acc/warna/manage"),
  },
  {
    name: "accwarna_update",
    path: "/warna/update/:id",
    component: () => import("../pages/acc/warna/manage"),
  },

  {
    name: "accorder_index",
    path: "/order",
    component: () => import("../pages/acc/order/index"),
  },
  {
    name: "accorder_create",
    path: "/order/create",
    component: () => import("../pages/acc/order/create"),
  },
  {
    name: "accorder_pabrik",
    path: "/order/harga-pabrik/:id",
    component: () => import("../pages/acc/order/update-harga-pabrik"),
  },
  {
    name: "accorder_currency",
    path: "/order/currency/:id",
    component: () => import("../pages/acc/order/update-currency"),
  },
  {
    name: "accorder_pengiriman",
    path: "/order/pengiriman/:id",
    component: () => import("../pages/acc/order/update-pengiriman"),
  },

  // {
  //   name: "accpelunasanp_index",
  //   path: "/pelunasan-pabrik",
  //   component: () => import("../pages/acc/pelunasanpabrik/index"),
  // },

  // {
  //   name: "accpelunasanc_index",
  //   path: "/pelunasan-customer",
  //   component: () => import("../pages/acc/pelunasancustomer/index"),
  // },
];
