import $axios from "../../api";
const state = () => ({
  page: 1,
  isSuccess: false,
  isSuccessDelete: false,
  isErrors: "",
  isLoading: false,

  list: null,
  detail: null,

  shoppingCart:[],
  resultCart:{}
});

const mutations = {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  SET_ERRORS(state, payload) {
    state.isErrors = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_SUCCESS(state, payload) {
    state.isSuccess = payload;
  },
  SET_SUCCESS_DELETE(state, payload) {
    state.isSuccessDelete = payload;
  },
  SET_LIST(state, payload) {
    state.list = payload;
  },
  SET_DETAIL(state, payload) {
    state.detail = payload;
  },

  SET_CART_SHOPPING(state, payload) {
    state.shoppingCart = payload;
  },
  SET_CART_RESULT(state, payload) {
    state.resultCart = payload;
  },
};

const actions = {
  getList({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`acc/order/list?page=${state.page}&view=${rootState.Main.view}${payload || ""}`)
        .then((res) => {
          commit("SET_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {root: true});
        });
    });
  },
  getDetail({ commit, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`acc/order/view?order_id=${payload}`)
        .then((res) => {
          commit("SET_DETAIL", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {root: true});
        });
    });
  },
  calculate({ commit, state, dispatch }) {
    commit("SET_LOADING", true);

    return new Promise((resolve, reject) => {
      $axios
        .post(`acc/order/calculate`, {cart: state.shoppingCart})
        .then((res) => {
          commit("SET_CART_RESULT", res.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {root: true});
        });
    });
  },
  deleteOrder({ commit, state, dispatch }) {
    // commit("SET_LOADING", true);

    commit("SET_SUCCESS_DELETE", true);    
    return false;
    // return new Promise((resolve, reject) => {
    //   $axios
    //     .post(`acc/order/calculate`, {cart: state.shoppingCart})
    //     .then((res) => {
    //       commit("SET_CART_RESULT", res.data);
    //       commit("SET_LOADING", false);
    //     })
    //     .catch((error) => {
    //       commit("SET_LOADING", false);
    //       dispatch("Main/globalSweetAlert", error.response, {root: true});
    //     });
    // });
  },
  checkout({ commit, state, dispatch }, payload) {
    commit("SET_LOADING", true);

    return new Promise((resolve, reject) => {
      $axios
        .post(`acc/order/create`, payload)
        .then((res) => {
          commit("SET_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {root: true});
        });
    });
  },
  updatePabrikPrice({ commit, state, dispatch }, payload) {
    commit("SET_LOADING", true);

    return new Promise((resolve, reject) => {
      $axios
        .post(`acc/order/update-pabrik-price`, payload)
        .then((res) => {
          commit("SET_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {root: true});
        });
    });
  },
  createPayment({ commit, state, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`acc/order/insert-payment`, payload)
        .then((res) => {
          commit("SET_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {root: true});
        });
    });
  },
  updateCurrency({ commit, state, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`acc/order/update-currency`, payload)
        .then((res) => {
          commit("SET_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {root: true});
        });
    });
  },
  updateShipping({ commit, state, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`acc/order/update-shipping`, payload)
        .then((res) => {
          commit("SET_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {root: true});
        });
    });
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};