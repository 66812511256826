//import vue instance
window.Vue = require("vue");

//import external library
require("./bootstrap");
import "./plugin/bootstrap-vue";
import "./plugin/days";
import "./plugin/sweetalert2";
import "./plugin/v-select";
import "./plugin/ck-editor";
import "./plugin/vue-image-upload-resize";
import "./plugin/vue-number-format";

//Import global function
import "./plugin/global-function"

//import initial global component setting
import "./importComponent";

//import store file (vuex)
import store from "./store";

//import routes file (routing)
import routes from "./routes/main";

//setting vue routing
import VueRouter from "vue-router";
Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  routes,
});

// export all setting
const app = new Vue({
  el: "#app",
  router,
  store
});
