import $axios from "../../api";

const state = () => ({
    isLoading: false,
    isErrors: "",
    isSuccess: false,
    page: 1,
    list: [],
    dataView:{},
});

const mutations = {
    SET_LOADING(state, payload) {
        state.isLoading = payload;
    },
    ASSIGN_ERRORS(state, payload) {
        state.isErrors = payload;
    },
    SET_SUCCESS(state, payload) {
        state.isSuccess = payload;
    },
    SET_PAGE(state, payload) {
        state.page = payload;
    },
    SET_LIST(state, payload) {
        state.list = payload;
    },
    SET_DATA_VIEW(state, payload) {
        state.dataView = payload;
    },
};

const actions = {
    list({ commit, state, rootState, dispatch }, payload) {
        commit("SET_LOADING", true);
        let query_str = payload;
        return new Promise((resolve, reject) => {
            $axios
                .get(`career/banner/list?page=` + state.page + "&" + query_str)
                .then((res) => {
                    commit("SET_LIST", res.data);
                    commit("SET_LOADING", false);
                })
                .catch((error) => {
                    commit("SET_LOADING", false);
                    dispatch("Main/globalSweetAlert", error.response, {
                        root: true,
                    });
                });
        });
    },
    create({ commit, state, rootState, dispatch }, payload) {
        commit("SET_LOADING", true);
        let image = null;
        if (payload.image.dataUrl == null) {
            image = null;
        } else {
            image = payload.image.dataUrl;
        }
        let data_create = {
            typebanner:payload.typebanner,
            title: payload.title,
            link: payload.link,
            image: image,
        };
        return new Promise((resolve, reject) => {
            $axios
                .post(`career/banner/create`, data_create)
                .then((res) => {
                    commit("SET_SUCCESS", true);
                    commit("SET_LOADING", false);
                })
                .catch((error) => {
                    commit("SET_LOADING", false);
                    commit("SET_SUCCESS", false);
                    commit("ASSIGN_ERRORS", error.response.data.errors);
                    dispatch("Main/globalSweetAlert", error.response, {
                        root: true,
                    });
                });
        });
    },
    update({ commit, state, rootState, dispatch }, payload) {
        commit("SET_SUCCESS", false);
        commit("SET_LOADING", true);
        let image = null;
        if (payload.image.dataUrl == null) {
            image = null;
        } else {
            image = payload.image.dataUrl;
        }
        let data_create = {
            typebanner:payload.typebanner,
            id:payload.id,
            title: payload.title,
            link: payload.link,
            image: image,
        };
        return new Promise((resolve, reject) => {
            $axios
                .put(`career/banner/update`, data_create)
                .then((res) => {
                    commit("SET_SUCCESS", true);
                    commit("SET_LOADING", false);
                })
                .catch((error) => {
                    commit("SET_LOADING", false);
                    commit("SET_SUCCESS", false);
                    commit("ASSIGN_ERRORS", error.response.data.errors);
                    dispatch("Main/globalSweetAlert", error.response, {
                        root: true,
                    });
                });
        });
    },
    view({ commit, state, rootState, dispatch }, payload) {
        commit("SET_LOADING", true);
        let id = payload;
        return new Promise((resolve, reject) => {
            $axios
                .get(`career/banner/view?id=`+id)
                .then((res) => {
                    commit("SET_DATA_VIEW", res.data);
                    commit("SET_LOADING", false);
                })
                .catch((error) => {
                    commit("SET_LOADING", false);
                    dispatch("Main/globalSweetAlert", error.response, {
                        root: true,
                    });
                });
        });
    },
    showHide({ commit, state, rootState, dispatch }, payload) {
        commit("SET_SUCCESS", false);
        commit("SET_LOADING", true);
        let attr_sender = {
            id:payload.id
        };
        return new Promise((resolve, reject) => {
            $axios
                .put(`career/banner/showhide`,attr_sender)
                .then((res) => {
                    commit("SET_SUCCESS", true);
                    commit("SET_LOADING", false);
                })
                .catch((error) => {
                    commit("SET_LOADING", false);
                    dispatch("Main/globalSweetAlert", error.response, {
                        root: true,
                    });
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
