window.Vue = require("vue");
import Vuex from "vuex";

//import Global Modules Vuex
import Main from "./stores/global/Main";
import User from "./stores/global/User";
import Sidebar from "./stores/global/Sidebar";
import Roles from "./stores/global/Roles";
import Module from "./stores/global/Module";
import Profile from "./stores/global/Profile";
import Select from "./stores/global/Select";
import Product from "./stores/global/Product";
import Member from "./stores/global/Member";
import Warehouse from "./stores/global/Warehouse";
import SyncUnion from "./stores/global/SyncUnion";
import Gallery from "./stores/global/Gallery";
import PriceGroup from "./stores/global/PriceGroup";
import UserPriceGroup from "./stores/global/UserPriceGroup";
import Global from "./stores/global/Global";

//import Reseller Modules Vuex
import Order from "./stores/reseller/Order";
import Dashboard from "./stores/reseller/Dashboard";

//Promotions
import Promo from "./stores/reseller/Promotions/Promo"
import PromoPrice from "./stores/reseller/Promotions/PromoPrice"
import PromoPercent from "./stores/reseller/Promotions/PromoPercent"
import PromoPercentDouble from "./stores/reseller/Promotions/PromoPercentDouble"
import PromoBuyGet from "./stores/reseller/Promotions/PromoBuyGet"
import PromoMinBuyGet from "./stores/reseller/Promotions/PromoMinBuyGet"
import PromoMinBuyGetMix from "./stores/reseller/Promotions/PromoMinBuyGetMix"
import PromoMinBuyGetMixDiscPrice from "./stores/reseller/Promotions/PromoMinBuyGetMixDiscPrice"

//import Website Modules Vuex
import Brand from "./stores/website/Brand";
import DashboardW from "./stores/website/Dashboard";
import Store from "./stores/website/Store";
import MemberW from "./stores/website/Member";
import PromoBadge from "./stores/website/promoBadge";
import Email from "./stores/website/Email";
import KeywordTag from "./stores/website/KeywordTag";

// CAREER PAGE
import PageContent from "./stores/career/PageContent"
import Banner from "./stores/career/Banner"
import Career from "./stores/career/Career"
import ApplyJob from "./stores/career/ApplyJob"

// INVENTORY PAGE
import Stock from "./stores/inv/Stock"
import Allocation from "./stores/inv/Allocation"
import SelectInv from "./stores/inv/SelectInv"
import TransferInv from "./stores/inv/TransferInv"

// ACCNIZAR
import AccBarang from "./stores/acc/Barang"
import AccWarna from "./stores/acc/Warna"
import AccPabrik from "./stores/acc/Pabrik"
import AccCustomer from "./stores/acc/Customer"
import AccOrder from "./stores/acc/Order"
import AccSelect from "./stores/acc/Select"
import AccDashboard from "./stores/acc/Dashboard"

const store = new Vuex.Store({
  modules: {
    Stock,
    Global,
    KeywordTag,
    Banner,
    Email,
    Dashboard,
    Gallery,
    Main,
    Member,
    Module,
    User,
    Order,
    Profile,
    Product,
    Roles,
    Sidebar,
    SyncUnion,
    Select,
    Warehouse,
    PromoPrice,
    PromoPercent,
    PromoPercentDouble,
    PromoBuyGet,
    PromoMinBuyGet,
    PromoMinBuyGetMix,
    PromoMinBuyGetMixDiscPrice,
    PageContent,
    Promo,
    Banner,
    Career,
    ApplyJob,
    PriceGroup,
    UserPriceGroup,
    Brand,
    Store,
    MemberW,
    PromoBadge,
    DashboardW,
    Allocation,
    SelectInv,
    TransferInv,

    AccBarang,
    AccWarna,
    AccPabrik,
    AccCustomer,
    AccOrder,
    AccSelect,
    AccDashboard
  },
});

export default store;

// Import semua modul secara dinamis
// const requireModule = require.context(
//     "./stores",
//     true,
//     /^(.*\.(js$))[^.]*$/i
//   );

//   const modules = requireModule
//     .keys()
//     .reduce((modules, modulePath) => {
//       const moduleName = modulePath
//         .replace(/^\.\/(.*)\.\w+$/, "$1")
//         .split("/")
//         .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
//         .join("");

//       const module = requireModule(modulePath);
//       modules[moduleName] = module.default || module;
//       return modules;
//     }, {});

//   const store = new Vuex.Store({
//     modules,
//   });

//   export default store;
