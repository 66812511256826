import $axios from "../../api";

// MODULE NAME
const module = "inv/select/";

const state = () => ({
    isLoading: false,
    isErrors: "",
    page: 1,
    isSuccess: false,
    productList: [],
    warehouseList: [],
    expDateList: [],
    orderList: [],
    orderDetail: null,
    orderDetailLoad: false,
});

const mutations = {
    SET_LOADING(state, payload) {
        state.isLoading = payload;
    },
    ASSIGN_ERRORS(state, payload) {
        state.isErrors = payload;
    },
    SET_PAGE(state, payload) {
        state.page = payload;
    },
    SET_IS_SUCCESS(state, payload) {
        state.isSuccess = payload;
    },
    SET_PRODUCT_LIST(state, payload) {
        state.productList = payload;
    },
    SET_WAREHOUSE_LIST(state, payload) {
        state.warehouseList = payload;
    },
    SET_EXP_DATE_LIST(state, payload) {
        state.expDateList = payload;
    },
    SET_ORDER_LIST(state, payload) {
        state.orderList = payload;
    },
    SET_ORDER_DETAIL(state, payload) {
        state.orderDetail = payload;
    },
    SET_ORDER_DETAIL_LOAD(state, payload) {
        state.orderDetailLoad = payload;
    },
};

const actions = {
    getProductList({ commit, state, rootState, dispatch }, payload) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            $axios
                .get(`${module}product?find=${payload || ""}`)
                .then((res) => {
                    commit("SET_PRODUCT_LIST", res.data.data);
                    commit("SET_LOADING", false);
                })
                .catch((error) => {
                    commit("SET_LOADING", false);
                    dispatch("Main/globalSweetAlert", error.response, {
                        root: true,
                    });
                });
        });
    },
    getWarehouseList({ commit, state, rootState, dispatch }, payload) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            $axios
                .get(`${module}warehouse?find=${payload || ""}`)
                .then((res) => {
                    commit("SET_WAREHOUSE_LIST", res.data.data);
                    commit("SET_LOADING", false);
                })
                .catch((error) => {
                    commit("SET_LOADING", false);
                    dispatch("Main/globalSweetAlert", error.response, {
                        root: true,
                    });
                });
        });
    },
    getExpDateList({ commit, state, rootState, dispatch }, payload) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            $axios
                .get(`${module}exp${payload || ""}`)
                .then((res) => {
                    commit("SET_EXP_DATE_LIST", res.data.data);
                    commit("SET_LOADING", false);
                })
                .catch((error) => {
                    commit("SET_LOADING", false);
                    dispatch("Main/globalSweetAlert", error.response, {
                        root: true,
                    });
                });
        });
    },
    getOrderList({ commit, state, rootState, dispatch }, payload) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            $axios
                .get(`${module}order?find=${payload || ""}`)
                .then((res) => {
                    commit("SET_ORDER_LIST", res.data.data);
                    commit("SET_LOADING", false);
                })
                .catch((error) => {
                    commit("SET_LOADING", false);
                    dispatch("Main/globalSweetAlert", error.response, {
                        root: true,
                    });
                });
        });
    },
    getOrderDetail({ commit, state, rootState, dispatch }, payload) {
        commit("SET_ORDER_DETAIL_LOAD", true);
        return new Promise((resolve, reject) => {
            $axios
                .get(`${module}order-detail?find=${payload || ""}`)
                .then((res) => {
                    commit("SET_ORDER_DETAIL", res.data.data);
                    commit("SET_ORDER_DETAIL_LOAD", false);
                })
                .catch((error) => {
                    commit("SET_ORDER_DETAIL_LOAD", false);
                    dispatch("Main/globalSweetAlert", error.response, {
                        root: true,
                    });
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
