import $axios from "../../api";

// MODULE NAME
const module = "badge"

const state = () => ({
    isLoading: false,
    isErrors: "",
    page: 1,
    isSuccess: false,
    list: null,
    detail: null,
});

const mutations = {
    SET_LOADING(state, payload) {
        state.isLoading = payload;
    },
    ASSIGN_ERRORS(state, payload) {
        state.isErrors = payload;
    },
    SET_PAGE(state, payload) {
        state.page = payload;
    },
    SET_IS_SUCCESS(state, payload) {
        state.isSuccess = payload;
    },
    SET_LIST(state, payload) {
        state.list = payload;
    },
    SET_DETAIL(state, payload) {
        state.detail = payload;
    },
};

const actions = {
    getList({ commit, state, rootState, dispatch }, payload) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            $axios
                .get(
                    `${module}/list?page=${state.page}&view=${
                        rootState.Main.view
                    }${payload || ""}`
                )
                .then((res) => {
                    commit("SET_LIST", res.data.data);
                    commit("SET_LOADING", false);
                })
                .catch((error) => {
                    commit("SET_LOADING", false);
                    dispatch("Main/globalSweetAlert", error.response, {
                        root: true,
                    });
                });
        });
    },
    getDetail({ commit, state, rootState, dispatch }, payload) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            $axios
                .get(`${module}/view?id=${payload}`)
                .then((res) => {
                    commit("SET_DETAIL", res.data.data);
                    commit("SET_LOADING", false);
                })
                .catch((error) => {
                    commit("SET_LOADING", false);
                    dispatch("Main/globalSweetAlert", error.response, {
                        root: true,
                    });
                });
        });
    },
    create({ commit, state, rootState, dispatch }, payload) {
        commit("SET_LOADING", true);
        commit("SET_IS_SUCCESS", false);
        return new Promise((resolve, reject) => {
            $axios
                .put(`${module}/create`, payload)
                .then((res) => {
                    commit("SET_IS_SUCCESS", true);
                    commit("SET_LOADING", false);
                })
                .catch((error) => {
                    commit("ASSIGN_ERRORS", error.response.data.errors);
                    commit("SET_LOADING", false);
                    dispatch("Main/globalSweetAlert", error.response, {
                        root: true,
                    });
                });
        });
    },
    update({ commit, state, rootState, dispatch }, payload) {
        commit("SET_LOADING", true);
        commit("SET_IS_SUCCESS", false);
        return new Promise((resolve, reject) => {
            $axios
                .put(`${module}/update`, payload)
                .then((res) => {
                    commit("SET_IS_SUCCESS", true);
                    commit("SET_LOADING", false);
                })
                .catch((error) => {
                    commit("ASSIGN_ERRORS", error.response.data.errors);
                    commit("SET_LOADING", false);
                    dispatch("Main/globalSweetAlert", error.response, {
                        root: true,
                    });
                });
        });
    },
    showHide({ commit, state, rootState, dispatch }, payload) {
        commit("SET_LOADING", true);
        commit("SET_IS_SUCCESS", false);
        return new Promise((resolve, reject) => {
            $axios
                .post(`${module}/showhide`, { id: payload })
                .then((res) => {
                    commit("SET_IS_SUCCESS", true);
                    commit("SET_LOADING", false);
                })
                .catch((error) => {
                    commit("SET_LOADING", false);
                    dispatch("Main/globalSweetAlert", error.response, {
                        root: true,
                    });
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};

